import { Result } from "antd";

const PageNotFound = () => {
  return (
    <Result
      status="warning"
      title="お探しのページが見つかりません"
      extra={
        <div
          style={{
            textAlign: "center",
            lineHeight: 2,
            fontSize: "18px",
          }}
        >
          <p>このページが移動または削除された可能性があります。</p>
          <p>ご不便をおかけして申し訳ございません。</p>
          <p>トップページから店舗やペットをお探しください。</p>
          <a href={"/"} type="primary">
            トップページへ
          </a>
        </div>
      }
    />
  );
};

export default PageNotFound;
